/**
 * ID: bh-base-mobile-theme-scss
 * Name: base.mobile.scss
 * Description: Mobile-specific variants of base stylesheet for Baystate Health themes
 * Version: 4
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Adjust cta-button style
 * 2021-07-27 - MW - v3: Added missing alert danger class
 * 2022-05-25 - MW - v4: Split global.scss into base.scss, base.mobile.scss, canned.scss
 */

 /*
 * Base Mobile CSS
 * ----------------------------------------------------------------------------
 * Base theming variants for narrow viewports (mobile).
 */

 @media (max-width: 767px) {

  .desktop-only,
  .desktop-only-flex,
  .desktop-only-inline {
    display: none !important;
  }

  .mobile-only {
    display: block !important;
  }

  .mobile-only-flex {
    display: flex !important;
  }

  ion-tabs {
    ion-tab-bar {
      // --background: rgba(255, 255, 255, 0.8);
      // background: rgba(255, 255, 255, 0.8) !important;
      // backdrop-filter: blur(2px);
    }
  }

}
